import { Navigate, useSearchParams } from "react-router-dom";

const ScanWithToken = () => {
  const searchParams = useSearchParams()[0];
  let callbackURL = "/";
  try {
    callbackURL = new URL(searchParams?.get("callback")).toString();
  } catch (err) {
    alert("Incorrect Callback URL");
  } finally {
    return <Navigate to="/select-option" replace state={{ callbackURL }} />;
  }
};

export default ScanWithToken;
