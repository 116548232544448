import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import NotFound from "screens/NotFound";
import SelectOption from "screens/SelectOption";
import FaceScan from "screens/FaceScan";
import FingerScan from "screens/FingerScan";
import Result from "screens/ScanResult";
import ScanWithToken from "screens/ScanWithToken";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="scan" element={<ScanWithToken />} />
        <Route path="select-option" element={<SelectOption />} />
        <Route path="face-scan" element={<FaceScan />} />
        <Route path="finger-scan" element={<FingerScan />} />
        <Route path="result" element={<Result />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
